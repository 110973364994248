module.exports = {
  generated: 'Generated',
  at: 'at',
  fromDG: 'from DobryGabinet.com',
  therapy: {
    patient: 'Patient',
    therapist: 'Therapist',
    typeOfNotes: 'Type of notes',
    printDate: 'Print date',
    practiceName: '@:sessions.pdfFields.practiceName',
  },
}
